@import "../../../../styles/index.scss";

.statisticSectionWrapper {
  margin: 70px 0px 100px 0px;
  color: $white;
  display: block;
  .boxSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 28px;
    justify-content: center;
  }
  .purposeSetion {
    margin: 100px 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 40px;
    max-width: 980px;
    .imageSection {
      max-width: 343px;
      flex-direction: row;
      flex-wrap: wrap;
      img {
        gap: 10px;
        max-width: 170px;
      }
    }
    .contentSection {
      text-align: left;
      color: $primaryLight;
      max-width: 580px;
      .title {
        font-size: 40px;
        color: $secondary;
        margin-bottom: 28px;
      }
      .description {
        font-size: 26px;
        .subText {
          font-size: 18px;
          margin-top: 30px;
          .subTextDesc {
            margin-top: 16px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
