@import "../../styles/index.scss";

.NavBarWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-bottom: 1px solid $ligthGrayWithOpacity;
  z-index: 100;
  min-height: 80px;
  .customContainer {
    max-width: 1100px;
  }
  .innerContainer {
    min-height: 80px;
  }
  nav {
    background-color: transparent !important;
    padding: 0px;
    min-height: 80px;
    a {
      font: normal normal 600 16px/19px Overpass;
      font-size: 16px;
      line-height: 19px;
      @media only screen and (min-device-width: 768px) and (orientation: landscape) {
        height: 80px;
      }
      align-items: center;
      display: flex;
    }
    :global .navbar-toggler {
      background-color: $secondary;
      color: $white;
    }
    .loginButton {
      height: 40px;
      width: 80px;
      font: normal normal 600 16px/19px Overpass;
      font-size: 16px;
      line-height: 19px;
    }
    .brandLogo {
      // border-right: 1px solid $ligthGrayWithOpacity;
      padding-right: 33.5px;
      padding-bottom: 18px;
      img {
        width: 159px;
      }
    }

    a {
      color: $white;
      &:hover {
        color: $secondary !important;
      }
      &:active,
      &:focus {
        color: $secondary !important;
      }
    }
  }
}
