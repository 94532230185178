@import "./../../styles/index.scss";

.aboutPageWrapper {
  min-height: 90vh;
  border-top: 80px solid $primary;
  padding-top: 2rem;
  .contentSection {
    max-width: 1100px;
  }
}
