@import "../../styles/index.scss";

.footerWrapper {
  .contenWrapper {
    justify-content: center;
    .footerNav {
      background-color: $primary !important;

      min-height: 96px;
      .customContainer {
        max-width: 1100px;
        a {
          color: $white !important;
          font-size: 16px !important;
          line-height: 38px !important;
          font: normal normal 300 16px/38px Overpass;
        }
        .centerLink {
        }
        .verticalSepration {
          color: $white;
          margin: 0px 11px 0px -9px;
          font-size: 14px;
        }
      }
    }
  }
}
