@import "../../../../styles/index.scss";

.solutionSectionWrapper {
  min-height: 828px;
  background: $primary;
  color: $white;
  .contentSection {
    justify-content: center;
    align-items: center;
    display: flex;
    .contenWrapper {
      justify-content: center;
      .title {
        padding-top: 108px;
        font-size: 40px;
        line-height: 46px;
        font: normal normal bold 40px/46px Overpass;
        .subtitle {
          color: gray;
          opacity: 0.8;
          font-size: 26px;
          font: normal normal 300 26px/46px Overpass;
        }
      }

      .listSection {
        padding-top: 57px;
        display: flex;
        flex-wrap: wrap;
        max-width: 1100px;
        justify-content: space-between;
        gap: 28px 0px;
        .text {
          font-size: 24px;
          min-width: 278px;
          font: normal normal 200 24px/46px Overpass;
          letter-spacing: 0px;
          color: $white;

          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
}
