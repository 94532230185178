@import "../../styles/index.scss";

.sliderComponentWrapper {
  color: $white !important;
  :global .slick-dots {
    display: flex !important;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    bottom: -12px !important;
    list-style-type: none;

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 16px;
      height: 10px;
      padding: 0;

      text-indent: -9999px;

      background: $darkGray 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
    }

    li.slick-active button {
      background-color: $secondary;
      //   width: 40px;
    }
  }
}
