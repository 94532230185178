@import "../../styles/index.scss";

.titleWrapper {
  .title {
    font-size: 40px;
    color: $secondary;
    font: normal normal 900 40px/55px Overpass;
  }
  .subTitle {
    font-size: 26px;
    color: $primaryLight;
    margin-top: 18px;
    font: normal normal 300 26px/46px Overpass;
  }
}
.clientTitle {
  .title {
    max-width: 122px;
  }
}
