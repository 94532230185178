@import "../../../../styles/index.scss";

.purposeSetion {
  margin: 100px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 40px;
  max-width: 980px;

  .imageSection {
    display: flex;
    .leftColumnImg {
      display: flex;
      flex-direction: column !important;
      max-width: 171px;
      gap: 10px;
      margin-right: 10px;
    }
    .rightColumnImg {
      display: flex;
      flex-direction: column !important;
      max-width: 171px;
      gap: 10px;
      padding-top: 30px;
    }
  }
  .contentSection {
    text-align: left;
    color: $primaryLight;
    max-width: 580px;
    .title {
      font-size: 40px;
      color: $secondary;
      margin-bottom: 28px;
      font: normal normal 900 40px/55px Overpass;
    }
    .description {
      font-size: 26px;
      font: normal normal 300 26px/38px Overpass;
      .subText {
        font-size: 18px;
        margin-top: 30px;
        line-height: 30px;
        font: normal normal normal 18px/30px Overpass;
        .subTextDesc {
          margin-top: 16px;
        }
      }
    }
  }
}
