@import "./../../styles/index.scss";
.homePageWrapper {
  padding: 0px !important;
  .headerSection {
    min-height: 500px;
    background: $primary;
    .sliderContentWrapper {
      min-height: 500px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      .sliderTitle {
        font: normal normal 300 30px/55px Overpass;
      }
      .sliderMainTitle {
        font: normal normal 900 50px/55px Overpass;
      }
      .sliderDescription {
        font: normal normal normal 18px/36px Overpass;
        letter-spacing: 0px;
        color: $white;
        opacity: 0.8;
        margin-top: 27px;
      }
    }
  }
  .contentSection {
    // padding: 0px 133px;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }
}
