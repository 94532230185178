@import "../../../../styles/index.scss";
.ourWorkSectionWrapper {
  .contentSection {
    max-width: 1100px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 90px;
    @media only screen and (min-device-width: 768px) and (orientation: landscape) {
      margin-top: -150px;
    }
    .titleSection {
      margin-bottom: 30px;
    }
    .sliderSectionWrapper {
      :global .slick-dots {
        bottom: -30px !important;
      }
      .sliderSection {
        max-width: 702px;
        padding: 10px;
        .sliderImage {
          max-width: 514px;
          max-height: 446px;
        }
      }
    }
  }
}
