@import "../../styles/index.scss";

.BoxComponentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0px;
  width: 253px;
  background: $primary 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 220px;

  .mainTitle {
    font-size: 16px;
    opacity: 0.8;
    font: normal normal normal 16px/46px Overpass;
    margin-bottom: 21px;
  }
  .value {
    font-size: 50px;
    font: normal normal 300 50px/46px Overpass;
    margin-bottom: 8px;
  }
  .unit {
    font-size: 20px;
    font: normal normal 300 20px/46px Overpass;
  }
}
