@import "../../../../styles/index.scss";
.ourClientWrapper {
  max-width: 1100px;
  min-height: 569px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 100px 60px;
  margin: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 1px $gray;
  gap: 0 32px;
  @media only screen and (min-device-width: 768px) and (orientation: landscape) {
    position: relative;
    top: -200px;
  }
  .dotSection {
    margin-top: 31px;
    @extend .d-block !optional;
    @extend .d-xs-none !optional;
    @extend .d-sm-none !optional;
    @extend .d-md-none !optional;
  }
  .logoSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 74%;
    gap: 0px 27px;
    align-items: center;
    .logoWrapper {
      width: 145px;
      height: 87px;
      justify-content: space-around;
      align-items: center;
      display: flex;
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
}
